<template>
  <div
    :class="isWorkApp ? 'dataModel dataModel-work' : 'dataModel'"
    :style="heightStyle"
    ref="datamodel"
  >
    <div class="header-nav" v-if="!isWorkApp && plat === 'APP'">
      <div class="back-btn" @click="popBack"><i class="el-icon-back"></i></div>
      <div class="list-title">
        {{ currentDCP.name && currentDCP.name.zh_CN }}
      </div>
    </div>
    <div class="modelContent" v-loading="loading">
      <!-- Render-->
      <el-form :model="cbJson" ref="form">
        <render
          :jsonContent="template"
          :datamodel="datamodel"
          :referenceList="referenceList"
          @fatherCall="setUpdateJSON"
          @dcpDetail="viewDcpDetail"
          @updateRefDcu="updateRefDcuData"
          @cacheDatamodel="cacheDatamodelData"
          :disabled="(!isReceiverScene && isPreview) || (isReceiverScene && receiverCannotEdit)"
          ref="childComponents"
          :contextData="contextData"
          :editStatus="editStatus"
          :invisibleKeyPathList="invisibleKeyPathList"
          :disableKeyPathList="disableKeyPathList"
        />
      </el-form>
    </div>

    <!--  Action Btn -->
    <div class="bottom-container" v-show="!isSoftKeyBoardShow">
      <el-row type="flex" justify="space-between" align="center">
        <!-- update -->
        <el-col v-if="isShowEditButton" :span="24">
          <el-button class="btn-white-color" @click="goUpdate" round :disabled="isDisabledEdit || isDeliverReceiveDisabled">{{ $t("app.update") }}</el-button>
        </el-col>
        <template v-else>
          <template v-if="!isPreview">
            <el-col v-if="isReceive" :span="24">
              <el-button class="btn-black-color" @click="onSubmit" round :disabled="is_disabled_by_receive_state">
                {{ !isAppReceiveCreate && is_disabled_by_receive_state ? $t("app.received") : $t('app.receive') }}
              </el-button>
            </el-col>
            <div class="footer-button" v-else>
              <el-col :span="11" v-if="!isUpdateTemplate">
                <el-button class="btn-white-color" @click="onSave" round>{{ $t("app.btn_save_draft") }}</el-button>
              </el-col>
              <el-col :span="!isUpdateTemplate ? 11 : 24" v-if="!isShowEditButton">
                <el-button class="btn-black-color" @click="onSubmit" round>{{ $t("app.btn_submit") }}</el-button>
              </el-col>
            </div>
          </template>
        </template>
      </el-row>
    </div>
  </div>
</template>

<script>
import callApp from "@/services/sdk";
import render from "@/views/dcpdetail/render";
import { mapGetters } from "vuex";
import { Indicator } from "mint-ui";
import VappAPI from "@/api/vapp";
import VeTrueApi from '@/api/vetrue';
import { workApp , DcpTypeMap, queryObject } from "@/utils";
import * as R from 'ramda';

import { Scene, SubmitType } from "../../utils/config";

export default {
  name: "index",
  components: {
    render,
    Indicator
  },
  data() {
    return {
      template: "",
      datamodel: "",
      cbJson: {},
      update: true,
      checkStatus: true,
      needCheckDeepth: 1,
      checkFinishDeepth: 0,
      referenceList: "",
      receivedDcp: "",
      refDcpConfigList: [],
      dcpreferences: [],
      catchError: "",
      scroll: 0,
      recordData: "",
      bizId: "",
      vwHeight: 0,
      loading: false,
      currentDCP: {},

      formLabelAlign: {
        receiverOrgId: "",
        senderOrgId: "",
        senderOrgName: ""
      },
      receiverGroupOptions: [],
      businessData: {},
      templateDisableKeyPath: [],
      templateDataModelData: {},

      // status
      isPreview: false,
      isSoftKeyBoardShow: false,
      originClientHeight: 0,
      sceneTemplateId: "",
      dcpId: '', // 拉取动态表单模版时返回的模版id，在提交时需要使用到
      plat: '', // WEB  APP
      dcp_template_id: '', // 模版详情id
      receiveData: {}, // 收货管理数据
      isReceiveBtnCanClick: false, // 收货管理新建时 - 有发货数据了，即可以设置为true，表示可以点击
      isDeliverReceiveDisabled: false, // 发货管理 - 已收货状态不能进行更新
    };
  },
  computed: {
    ...mapGetters({
      formSet: "getFormSet",
      formStatus: "getFormStatus",
      softDataStatus: "getSoftDataStatus",
      dataPointType: "getDataPointType"
    }),
    heightStyle() {
      return "min-height:" + this.vwHeight + "vw";
    },
    contextData() {
      return {
        refDcpConfigList: this.refDcpConfigList,
        dcpreferences: this.dcpreferences
      };
    },
    editStatus() {
      if (
        this.formStatus &&
        this.formStatus.indexOf("edit") > -1 &&
        this.update
      ) {
        return "watch";
      } else if (this.formStatus && this.formStatus.indexOf("init") > -1) {
        return "init";
      } else if (this.formStatus && this.formStatus.indexOf("edit") > -1) {
        return "edit";
      }
      return "watch";
    },
    isWorkApp() {
      return workApp;
    },
    isUpdateTemplate() {
      return this.$route.query && this.$route.query.dcp_template_id;
    },
    isSenderScene() {
      return this.$route.query.scene === Scene.LOGISTIS_SEND;
    },
    isReceiverScene() {
      return this.$route.query.scene === Scene.LOGISTIS_RECEIVE;
    },
    // TODO 芯片录入缺少 bless 主体参数，导致访问失败
    // https://vetrue-h5.veplatform.cn/?e=f&c=cn&m=100253&d=MTG_010
    isBlessUrlError() {
      // 判断条件: 只要有这几个参数 e c m d, 即判断为失败链接
      const query = this.$route.query;
      return query.e && query.c && query.m && query.d;
    },
    invisibleKeyPathList() {
      let keyPathList = [];
      if (this.isSenderScene) {
        keyPathList.push("base.receiver");
      }
      if (this.isReceiverScene) {
        keyPathList.push("base.sender_info");
      }
      return keyPathList;
    },
    isDisabledEdit() { // 状态为进行中 PROCESSING, SUBMIT 不能修改      只有是创建者才能修改自己的数据(isCreate) 0 表示非创建者
      const isCreate = Number(this.$route.query.isCreate);
      return this.shipSubmitType === 'PROCESSING' && this.shipSubmitType === 'SUBMIT' || isCreate === 0;
    },
    isShowEditButton() {
      return this.isPreview;
    },
    receiverCannotEdit() {
      let dataSatusCannotReceive = this.businessData && this.businessData.asnOrderInfo.status === 'RECEIVED'
      return (
        this.isPreview &&
        this.shipSubmitType === SubmitType.SUBMIT &&
        !this.shipStatusEditable || dataSatusCannotReceive
      );
    },
    shipSubmitType() {
      const submitType = R.pathOr('', ['state'])(this.businessData);
      return submitType;
    },
    shipStatusEditable() {
      return this.businessData && this.businessData.editable;
    },
    disableKeyPathList() {
      let submitType = this.businessData.softDataInfo && this.businessData.softDataInfo.submitType
      return submitType === SubmitType.SUBMIT ? ['base.vidlist', 'base.bigvid', 'base.skuInfo'] : []
    },
    isReceive() { // 收货管理
      const dcp_type = this.$route.query?.dcp_type;
      if (dcp_type === 'receive') {
        this.$store.dispatch("setPageName", 'RECEIVE'); // 页面名称
      }
      return dcp_type === 'receive';
    },
    is_disabled_by_receive_state() { // 收货管理状态，影响底部按钮
      const receiveState = this.receiveData?.receive_state;
      if (receiveState === 'WAIT_RECEIVE' || receiveState === 'FAILURE') {
        // 1、待收货/收货失败：显示收货按钮   2、处理中/收货成功：不显示按钮
        return false;
      } else if (this.isReceiveBtnCanClick) {
        return false;
      }
      return true;
    },
    isAppReceiveCreate() { // 收货管理app新建
      return this.$route.query?.batch_code === 'null'
    },
    isDeliverUpdate() { // 发货管理修改
      const query = this.$route.query
      return query.dcp_data_type = 'DELIVER' && query.biz_data_id && query.data_id;
    }
  },
  beforeRouteLeave(to, from, next) {
    let position = document.documentElement && document.documentElement.scrollTop; //记录离开页面时的位置

    if (position == null) position = 0;
    this.$store.state.indexScroll = position;
    next();
  },

  async mounted() {
    const query = this.$route.query;
     // TODO 特殊口子
    if (this.isBlessUrlError) {
      this.$router.push({ path: "/bless", query: { ...query } });
    }

    let templateId = query.templateId;
    this.sceneTemplateId = templateId;
    const dcp_template_id = query.dcp_template_id || '';
    this.plat = query.plat;

    const bizDataId = query.biz_data_id;

    this.isProductBind();

    if (!R.isEmpty(dcp_template_id)) { // 更新模版数据逻辑
      this.getTemplateUpdate(dcp_template_id);
    } else {
      await this.getSceneDcpModel();
      if (!bizDataId && !this.isAppReceiveCreate && this.isReceive) { // 收货管理详情修改
        this.getReceiveDataByBatchCodeOrReceiveId({ data_id: query.data_id }); // 修改时拿id能查到精确的值
      }
      if (query.plat === 'WEB' && query.batch_code) { // 收货管理  web端新增
        this.getReceiveDataByBatchCodeOrReceiveId({ batch_code: query.batch_code });
      }
      if (this.isDeliverUpdate) {
        await this.getReceiveDataByBatchCodeOrReceiveId({ data_id: query.data_id }); // 发货管理数据
        const deliverState = this.receiveData?.deliver_state;
        this.isDeliverReceiveDisabled = deliverState === 'RECEIVE' || deliverState === 'PROCESSING' || deliverState === 'SUCCESS'; // 发货管理 - 已收货、已发货、发货中状态不能进行更新, 此时更新按钮不能点击
      }
    }
  },
  methods: {
    async getDeliverData(deliverId) { // 获取发货管理 model 数据，为收货管理页面填充数据使用
      const query = this.$route.query;
      const deliverDcpDetail = await VeTrueApi.getDcpDetail({ query, bizDataId: deliverId });

      // 如果 dcpDetail.tc_dcp_data 下面没有数据，就用 dcpDetail.data 对象的值
      const data = deliverDcpDetail.tc_dcp_data === null || Object.keys(deliverDcpDetail.tc_dcp_data).length === 0 ? deliverDcpDetail.data : R.pathOr({}, ['tc_dcp_data', 'dcpDataInfo', 'dcpDataInfo'])(deliverDcpDetail);
      this.$store.dispatch("setDeliverDcpData", data || {}); // 缓存发货管理 - 动态表单结构数据
    },
    isProductBind() {
      const dcp_data_type = this.$route.query?.dcp_data_type;
      if (dcp_data_type === 'PRODUCT_BIND') {
        this.$store.dispatch("setPageName", 'PRODUCT_BIND'); // 页面名称
      }
      return dcp_data_type === 'PRODUCT_BIND'
    },
    // 创建时 - 根据 batch_code，修改时 - 根据 data_id 查询收货管理数据
    async getReceiveDataByBatchCodeOrReceiveId(params) {
      const query = this.$route.query;
      const receiveData = await VeTrueApi.getReceiveDataByBatchCodeOrReceiveId({ query, params });
      this.receiveData = receiveData;

      this.$store.dispatch("setReceiveInfo", receiveData || {}); // 缓存收货详情数据

      const receiveState = this.receiveData?.receive_state;
      // 收货状态为 待收货时表示收货数据还没有创建，此时使用发货 deliver_data_id 来铺数据，否则表示数据已经创建，使用收货 receive_data_id
      const bizDataId = receiveState === 'WAIT_RECEIVE' ? this.receiveData?.deliver_data_id : this.receiveData?.receive_data_id;
      if (bizDataId) await this.renderModelData(bizDataId);
      if (this.receiveData?.deliver_data_id) {
        await this.getDeliverData(this.receiveData?.deliver_data_id);
      }
    },
    async renderModelData(bizDataId) { // 获取动态表单值并进行填充
      const query = this.$route.query;
      const dcpDetail = await VeTrueApi.getDcpDetail({ query, bizDataId });
      this.businessData = dcpDetail;

      let submitType = R.pathOr('', ['state'])(dcpDetail);
      let isCopy = query.copyRecord;
      let disabledKeyPath = submitType === SubmitType.DRAFT ? [...this.templateDisableKeyPath] : ['base.skucode','base.vidlist', 'base.bigvid', 'base.skuInfo', ...this.templateDisableKeyPath];
      if (isCopy) {
        disabledKeyPath = [...this.templateDisableKeyPath]
      }
      this.$store.dispatch("setDisabledKeyPathList", disabledKeyPath);
      this.$store.dispatch("setFormStatus", query.batch_code === 'null' ? "init" : "edit"); // formStatus 这个参数在收货管理批次信息组件展示时被渲染成修改时的状态，因此要做兼容
      this.$store.dispatch("setSoftDataStatus", query.statusData_softDataStatus || "enable");

      // 如果 dcpDetail.tc_dcp_data 下面没有数据，就用 dcpDetail.data 对象的值
      this.datamodel = dcpDetail.tc_dcp_data === null || Object.keys(dcpDetail.tc_dcp_data).length === 0 ? dcpDetail.data : R.pathOr({}, ['tc_dcp_data', 'dcpDataInfo', 'dcpDataInfo'])(dcpDetail);
      this.$store.dispatch("setDcpDataInfo", this.datamodel || {}); // 缓存动态表单提交的数据
    },
    // New 拉取动态表单模版
    async getSceneDcpModel() {
      const query = this.$route.query;

      const project_uuid = query.project_uuid;
      const dcp_data_type = query.dcp_data_type;

      if (!project_uuid) {
        callApp("setNavBarTitle", { type: "navbar.updateNavBarTitle",
            data: {
              key: "title",
              val: this.$t('feture.params_error')
            }
          },
          "cbSetNavBarTitle",
          () => {},
          undefined
        );
        return;
      }

      let dcpModel = null;
      // (旧的 app 版本)只有 PRODUCT 有 dcp_instance_uuid 参数, 新版本 将都使用 dcp_instance_uuid 查询
      // 因此两种参数请求都支持，需要同时保持，app未升级

      // 也就是只要有 dcp_data_type 参数就使用 dcp_data_type 进行查询
      if (dcp_data_type) {
        const params = { project_uuid, dcp_data_type };
        dcpModel = await VeTrueApi.getSceneDcpModel({ query, params });
      } else {
        const params = { dcp_instance_uuid: query.dcp_instance_uuid };
        dcpModel = await VeTrueApi.getDcpModelByUUID({ query, params });
      }

      console.log(dcpModel, 'dcpModel');

      if (R.isEmpty(dcpModel)) {
        this.$message({ message: this.$t('feture.get_sku_failed'), type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
        return;
      }

      // 动态表单多语言
      const language = R.pathOr({}, ['model', 'language'])(dcpModel);
      if (language && Object.keys(language).length > 0) {
        this.$store.state.languagePackage.push(language);
      }

      this.$store.dispatch("setFormStatus", "init");
      let dcpTypeUuid = R.pathOr('', ['model', 'model', 'uuid'])(dcpModel);
      this.dcpId = R.pathOr('', ['id'])(dcpModel);
      let dcpType = "custom";
      if (dcpTypeUuid && dcpTypeUuid.length > 6) {
        let uuid = dcpTypeUuid.slice(dcpTypeUuid.length - 6, dcpTypeUuid.length);
        dcpType = DcpTypeMap[uuid];
      }
      this.$store.dispatch("setDataPointType", dcpType);

      let bizDataId = query.biz_data_id; // 提交历史数据的回显，列表记录被点击后要进行更新
      let orderNo = query.orderNo;
      if (bizDataId || orderNo) {
        await this.renderModelData(bizDataId);

        this.$store.dispatch("setDataPointType", dcpType);
        this.template = R.pathOr([], ['model', 'model', 'attributes'])(dcpModel);
        this.referenceList = R.pathOr([], ['model', 'referenceList'])(dcpModel);

      } else {
        // 有模版功能后，需要拿 dcpModel.id 去查模版预设的数据
        const templateDetail = await VeTrueApi.getTemplateDetail({ query, dcpTemplateId: dcpModel.id });
        if (templateDetail.template === null) { // 模版数据为空
          this.templateDataModelData = {};
        } else {
          this.templateDataModelData = templateDetail.template;
        }
        this.renderForTypeAdd(dcpModel);
      }
    },
    renderForTypeAdd(dcpModel) { // 动态表单新建操作渲染，无任何填充数据
      this.template = R.pathOr([], ['model', 'model', 'attributes'])(dcpModel);
      this.referenceList = R.pathOr([], ['model', 'referenceList'])(dcpModel);

      this.datamodel = this.templateDataModelData || {};
      let disabledKeyPath = [...this.templateDisableKeyPath] // 将品类数据放到全局中
      this.$store.dispatch("setDisabledKeyPathList", disabledKeyPath);
    },
    async getTemplateUpdate(id) {
      const query = this.$route.query;
      const project_uuid = query.project_uuid;
      const dcp_data_type = query.dcp_data_type;
      const params = { project_uuid, dcp_data_type };
      const dcpModel = await VeTrueApi.getSceneDcpModel({ query, params });

      const language = R.pathOr({}, ['model', 'language'])(dcpModel);
      if (language && Object.keys(language).length > 0) {
        this.$store.state.languagePackage.push(language);
      }

      // 获取模版详情
      const templateDetail = await VeTrueApi.getTemplateDetail({ query, dcpTemplateId: id });
      if (templateDetail.template === null) { // 模版数据为空，表示初次修改，只渲染动态表单数据即可
        this.renderForTypeAdd(dcpModel);
      } else {
        this.businessData = templateDetail;
        this.datamodel = templateDetail.template || {}; // 这里是模版详情要回显的部分
        this.template = R.pathOr([], ['model', 'model', 'attributes'])(dcpModel);
        this.referenceList = R.pathOr([], ['model', 'referenceList'])(dcpModel);
      }
    },
    popBack() {
      this.$router.back();
    },
    setUpdateJSON(data) {
      this.cbJson = data.value;
      this.$forceUpdate();
    },
    cacheDatamodelData() {
      this.$store.state.cacheData = this.cbJson;
    },
    updateRefDcuData(data) {
      let action = data.action;
      let refData = data.refData;
      let storagekey = refData.storagekey;
      if (!this.dcpreferences || this.dcpreferences === undefined) {
        this.dcpreferences = [];
      }
      this.dcpreferences = (this.dcpreferences && this.dcpreferences.filter(element => {
        return element.storagekey !== storagekey;
      })) || [];
      if (action === "update") {
        this.dcpreferences.push(refData);
      }
    },
    resetCheckStatus() {
      this.checkStatus = true;
      this.needCheckDeepth = 1;
      this.checkFinishDeepth = 0;
    },

    onSave() {
      this.$store.state.cacheData = {};
      this.$store.dispatch("setViewDcp", {});
      this.$store.dispatch("setChildPageContext", {});
      this.$store.state.preEditDataModelUuid = "";
      this.$store.state.preEditStatus = true;
      this.resetCheckStatus();

      if (this.isSenderScene) {
        this.$refs.receiver.validate(async valid => {
          if (valid) {
            this.checkFormSubmit(this.$refs.childComponents.$refs, false, SubmitType.DRAFT);
          }
        });
      } else {
        this.checkFormSubmit(this.$refs.childComponents.$refs, false, SubmitType.DRAFT);
      }
    },

    onSubmit() {
      this.$store.state.cacheData = {};
      this.$store.dispatch("setViewDcp", {});
      this.$store.dispatch("setChildPageContext", {});
      this.$store.state.preEditDataModelUuid = "";
      this.$store.state.preEditStatus = true;
      this.resetCheckStatus();

      if (this.isSenderScene) {
        this.$refs.receiver.validate(async valid => {
          if (valid) {
            this.checkFormSubmit(this.$refs.childComponents.$refs, false, SubmitType.SUBMIT);
          }
        });
      } else {
        this.checkFormSubmit(this.$refs.childComponents.$refs, false, SubmitType.SUBMIT);
      }
    },

    async submit(submitType) {
      let json = { ...this.cbJson, dcpreferences: this.dcpreferences };
      let uploadData = JSON.parse(JSON.stringify(json));
      let bizDataId = this.$route.query.biz_data_id;

      if (this.isSenderScene) {
        uploadData.base.receiver = this.receiverGroupOptions.filter(item => item.subordinateSuborgUuid === this.formLabelAlign.receiverOrgId)[0].subordinateSuborgUuid;
      }

      let isCopy = this.$route.query.copyRecord;
      if (isCopy) {
        bizDataId = undefined;
      }

      if (this.isReceiverScene) {
        let sendDataInfo = this.businessData.asnSendInfo.dataInfo;
        let blockChainInfo = this.businessData.asnSendInfo.blockChainInfo;
        uploadData.base.sender_info = [
          {
            datauuid: sendDataInfo.dataUuid,
            uuid: sendDataInfo.dataUuid,
            datavid: blockChainInfo.dataVid,
            datahash: blockChainInfo.onChainDataHash,
            txid: blockChainInfo.txId,
            clauseindex: ~~blockChainInfo.clauseIndex
          }
        ];
      }

      let dataValueExtend = { PREFIX_SUFFIX_CODE: this.$store.state.extendList }

      let submitData = {
        dataValue: uploadData,
        bizLanguage: "zh-CN",
        device: "web",
        submitType: submitType,
        bizDataId,
        dataValueExtend,
        scene: this.$route.query.scene,
        sceneDcpId: this.$route.query.sceneDcpId,
        sceneGroup: this.$route.query.sceneGroup,
        templateId: this.sceneTemplateId
      };

      // 更新模版数据
      const dcp_template_id = this.$route.query.dcp_template_id || '';

      let data = undefined;

      let baseParams = {
        data: { ...uploadData, }, // 后端接口只需要 uploadData
        query: this.$route.query,
        is_draft: submitType === SubmitType.DRAFT ? true : false,
      }

      const selectReceive = this.$store.state.selectReceive;
      if (selectReceive && Object.keys(selectReceive).length !== 0) { // 发货管理增加参数 receive_department_id
        baseParams = { ...baseParams, receive_department_id: selectReceive.id };
      }

      const query = this.$route.query;
      const batch_code = query?.batch_code; // 收货管理加 data_id
      if (batch_code || batch_code === 'null') {
        const receiveInfo = this.$store?.state?.receiveInfo;
        baseParams = { ...baseParams, data_id: receiveInfo.id };
      }

      try {
        if (bizDataId) { // 当bizDataId有数据时, 更新动态表单数据
          data = await VeTrueApi.updateDcpModel({
            dcp_data_id: bizDataId,
            ...baseParams,
          });
        } else if (!R.isEmpty(dcp_template_id)) { // 更新模版数据
            await VeTrueApi.updateTemplate({
              template: { ...uploadData, },
              query: this.$route.query,
              dcp_id: dcp_template_id,
            })
        } else {

          data = await VeTrueApi.submitDcpModel({
            dcp_id: this.dcpId,
            ...baseParams,
          });
        }
      } catch (error) {
        const detail = R.pathOr('', ['response', 'data', 'detail'])(error);
        let msg = '';
        if (typeof detail === 'string') {
          msg = detail;
        } else {
          msg = detail.code === 400015 ? this.t('app.dcp_error_400015') : detail.msg;  // 特殊code处理
        }
        this.$message({ message: msg, type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
        return;
      }

      if (this.plat === 'WEB') {
        // const query = this.$route.query;
        const query = queryObject()
        // 这里用于将admin的抽屉层关闭，因此要兼容两种数据
        const data = query.dcp_data_type ? query.dcp_data_type : query.dcp_instance_uuid;
        parent.postMessage(`${data}`, '*');
      }

      this.$message({ message: this.$t('feture.create_success'), type: "success", offset: 300, duration: 4000 , customClass: 'el-message-custom'});

      // 成功需要通知app
      callApp("appComplete", {
          type: "app.complete",
          data: {
            key: "title",
            val: 'success',
          }
        },
        "cbSetNavBarTitle",
        () => {},
        undefined
      );
    },
    uniteValidate(unite) {
      unite.$refs.childForm.validate(validateResult => {
        if (this.isUpdateTemplate) { // 模版修改直接返回true表示不校验
          this.checkStatus = true;
        } else {
          this.checkStatus = this.checkStatus && validateResult;
        }
        return this.checkStatus;
      });
    },
    checkFormSubmit(check, outStatus, submitType) {
      if (check.childComponents !== undefined) {
        for (let index = 0; index < check.childComponents.length; index++) {
          const item = check.childComponents[index];
          if (item.$refs.childComponents !== undefined) {
            this.needCheckDeepth += 1;
            this.checkFormSubmit(item.$refs, false, submitType);
          } else if (item.$refs.childForm !== undefined) {
            this.uniteValidate(item);
          }

          // 根据key拆分的组件，层次结构发生了变化，多加上了一层，因此在增加的这层加上一个 ref="special"，从而有了这个分支判断
          if (item.$refs.special !== undefined) {
            const nextLevel = item.$refs.special;
            this.uniteValidate(nextLevel);
          }
        }
        this.checkFinishDeepth += 1;

        /// 根据检查层级判断有没有检查完
        if (this.checkFinishDeepth > 1 && this.needCheckDeepth === this.checkFinishDeepth) {
          if (this.checkStatus) {
            this.submit(submitType);
          } else {
            console.log("check_failed", this.checkStatus)
          }
        }
      }
    },
    goUpdate() {
      this.isPreview = false;
    },
    viewDcpDetail(dcp) {
      this.receivedDcp = dcp;
      this.$store.dispatch("setViewDcp", dcp);
      this.$router.push({ path: "/v2/dcpdetail", query: { action: "update" } });
    },
  },
  watch: {
    "$route.query.biz_data_id": {
      immediate: true,
      handler: function (val) {
        if (val) {
          let copyRecord = this.$route.query.copyRecord;
          if (!copyRecord) {
            this.isPreview = true;
          }
        }
      }
    },
    "$store.state.batchCodeWatch": {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.getReceiveDataByBatchCodeOrReceiveId({ batch_code: val }); // 当app收货管理 - 新增时切换了 批次信息，将刷新model
          this.isReceiveBtnCanClick = true;
        }
      }
    }
  },
  created() {

  },
  beforeMount() {
    this.originClientHeight = document.body.clientHeight || document.documentElement.clientHeight;
    let w = document.body.clientWidth || document.documentElement.clientWidth;
    this.vwHeight = (this.originClientHeight/w)*100;

    window.onresize = () => {
      return (() => {
        let currentClientHeight = document.body.clientHeight || document.documentElement.clientHeight;
        this.isSoftKeyBoardShow = (this.originClientHeight - currentClientHeight > 50)
      })()
    }
  }
};
</script>
<style lang="scss" scoped>
.dataModel {
  display: flex;
  height: 100vh;
  flex-direction: column;
  margin: 0 auto;
  width: 100vw;
  max-width: 750px;
  padding-top: 28px;

  .modelContent {
    flex: 1 0 auto;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vw - 80px);
    min-height: calc(100vw - 80px);
    padding: 0 35px;
  }

  .bottom-container {
    padding: 16px;
    margin: 0;
    height: 80px;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1002;
  }
}

.box-card {
  .el-card__body {
    height: calc(100vh - 360px);
    overflow-y: scroll;

    .no-data {
      text-align: center;
      opacity: 0.5;
    }

    .scan-result {
      padding: 10px 0px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid lightgrey;

      .left {
        word-break: break-word;
      }

      .right {
        text-align: right;
        width: 90px;
      }
    }
  }
}

.dataModel-work {
  padding-top: 0;
}

.footer-button {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
</style>
